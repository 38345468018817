import classes from "./reviewCard.module.css";
import React, { useEffect, useState } from "react";
import PinDropIcon from "@mui/icons-material/PinDrop";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { Link } from "react-router-dom";
import { Skeleton } from "antd";
import countryFlags from "../../assets/data/countryFlags";
import EmailIcon from "@mui/icons-material/Email";
import avatarImg from "../../assets/imgs/avatar2.png";
import Rating from "@mui/material/Rating";

const ReviewCard = (props) => {
  function parseDate(date) {
    if (!date) return "";
    return new Date(date).toLocaleString().split(",")[0].replaceAll("/", "-");
  }
  
  return (
    <div className={classes.card}>
      {props.load ? (
        <div className={classes.imgSkeleton}>
          <Skeleton.Avatar shape={"square"} size={100} active />
        </div>
      ) : (
        <div className={classes.cardImage}>
        { props.img === "" || props.img === null ?
          <img src={avatarImg} alt="event_img" />:
          <img src={props.img} alt="event_img" />
        }
        </div>
      )}
      {props.load ? (
        <div className={classes.txtSkeleton}>
          <Skeleton active />
        </div>
      ) : (
        <ul className={classes.cardContent}>
          <div className={classes.userInfo}>
            <h3 data-testid="title">{props.title}</h3>
            {/* <div className={classes.contactUs}>
              <div className={classes.contactUsBtn}>
                <EmailIcon /> 
                <p> Contact me</p>
              </div>

              <div className={classes.contactInfo}>
                <h5>Email:</h5>
                <p>{props.email}</p>
                <h5>Phone:</h5>
                <p>{props.phone}</p>
              </div>
            </div> */}
          </div>
          <div className={classes.sectionDetails}>
            <div className={classes.tripInfo}>
            <div className={classes.programName}>{props.programName}</div>
            <div className={classes.location}>
              <PinDropIcon /> {props.location}
            </div>
            <Rating name="read-only" value={props.rating}  readOnly />
            </div>
            {/* {
            countryFlags.find((e) => e.country === props.nationality) !== undefined &&
            <img
              src={countryFlags.find((e) => e.country === props.nationality)?.flag}
              alt="flag"
            />
            } */}
          </div>
          <div className={classes.time}>
            <DateRangeIcon />
            {parseDate(props.startDate)}
            {"  to  "}
            {parseDate(props.endDate)}
          </div>
          <div className={classes.content}>{props.content}</div>
          <Link to={`/programs/${props?.progId}`}>
            <button className={classes.btn}>
              {props.isReview ? "Review Program" : "Read More"}{" "}
              <TrendingFlatIcon />{" "}
            </button>
          </Link>
        </ul>
      )}
    </div>
  );
};

export default ReviewCard;

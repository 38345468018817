import React, { useState } from "react";
import downArrow from "../../../assets/imgs/programs/down.svg";
import icon from "../../../assets/imgs/programs/helpIcon.svg";
import classes from "./getStarted.module.css";


const GetStarted = () => {
  const [showModal, setShowModal] = useState(false);
  const steps = ["Apply for visa", "Upload you image", "Choose the required program", "Apply for the program and wait for the response"];

  return (
    <div className={classes.container}>
      <div className={classes.button} onClick={() => { setShowModal(!showModal) }}>
        <img src={icon} alt="icon" className={`${!showModal && classes.visible}`} />
        <img src={downArrow} alt="icon" className={`${showModal && classes.visible}`} />
      </div>

      <div className={`${classes.modal} ${showModal && classes.expanded}`}>
        <h3>How To Get Started</h3>
        <ul>
          {steps.map((step, index) => (
            <li className={classes.step}>{step}</li>
          ))}
        </ul>

      </div>

    </div>
  );
};

export default GetStarted;

import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SectionTitle from "../../generic components/secTitle/SectionTitle";
import GlobeLoader from "../../layouts/globeLoader/GlobeLoader";
import axios from "../../requests/axios";
import routes from "../../requests/routes";
import BookingModal from "./Booking/BookingModal";
import Itinerary from "./cardsItinerary/Itinerary";
import ExpandableSection from "./expandableSection/ExpandableSection";
import Highlights from "./highlights/Highlights";
import PriceCard from "./price card/PriceCard";
import PriceDetails from "./priceDetails/PriceDetails";
import classes from "./programDetails.module.css";
import { toast } from "react-hot-toast";

import Anchor from "../home/anchor/anchor";
import Activities from "./activities/activities";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import Facebook from "../../utils/FacebookPixel";

function ProgramDetails() {
  const [program, setProgram] = useState({});
  const [openBooking, setOpenBooking] = useState(false);
  const user = useSelector((state) => state.user);
  const [bookLink, setBookLink] = useState("");
  const [currency, setCurrency] = useState("$");
  const logged = user.loggedIn;
  const navigate = useNavigate();

  const id = useParams().id;

  function parseDate(date) {
    return new Date(date).toLocaleString().split(",")[0].replaceAll("/", "-");
  }

  async function getProgram() {
    try {
      const response = await axios.get(
        encodeURI(routes.getPrograms + "/" + id)
      );
      setProgram(response.data.program);
    } catch (err) { }
  }

  async function getUserBookLink() {
    try {
      const response = await axios.post(routes.getUserBookLink + "/" + id);
      return response.data.link;
    } catch (err) { }
  }

  function openGoogleForm() {
    //check if user is logged in
    if (program.isAvailable === false) {
      toast.error("This program is not available now");
      return;
    }

    // if (!logged) {
    //   toast((t) => (
    //     <div style={{ display: "flex", gap: "2rem" }}>
    //       Please login to book the program
    //       <button
    //         style={{
    //           backgroundColor: "#F46444",
    //           color: "white",
    //           padding: "0.5rem 1rem",
    //           border: "none",
    //           borderRadius: "5px",
    //         }}
    //         onClick={() => {
    //           toast.dismiss(t.id);
    //           navigate("/login", { state: { from: window.location.pathname } });
    //         }}
    //       >
    //         Login
    //       </button>
    //     </div>
    //   ));

    //   return;
    // } else {
      if (!program?.googleForm) {
        return;
      }
      // const googleBaseLink = program.googleForm;
      // const googleLink = googleBaseLink
      //   .replace("full_name", user?.fullName)
      //   .replace("email", user?.email)
      //   .replace("phone_number", user?.phoneNO);

      //track booking event on facebook pixel
      fbq("track", "Purchase", {
        content_name: program?.name,
        content_id: id,
        content_category: "Programs",
      });  

      window.open(bookLink, "_blank");
    // }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getProgram();

    //get link
    //if (logged) {
      getUserBookLink().then((link) => {
        setBookLink(link);
      });
    //}
  }, []);

  useEffect(() => {
    if (program.currency === "USD") {
      setCurrency("$");
    } else if (program.currency === "EUR") {
      setCurrency("€");
    } else if (program.currency === "EGP") {
      setCurrency("EGP");
    }
  }, [program]);
/* global fbq */

  useEffect(() => {
    // Track custom event for program visits
    fbq("track", "ViewContent", {
      content_name: program?.name,
      content_id: id, 
      content_category: "Programs",
    });
  }, [id, program]);


  return (
    <>

      {Object.keys(program).length === 0 ? (
        <GlobeLoader />
      ) : (
        <div
          className={classes.wrapper}
          style={{ backgroundImage: "url(" + program?.backgroundImg + ")" }}
        >
          <NavLink to="/programs">
            <ArrowCircleLeftIcon
              style={{ color: "#f8f8f8" }}
              className={classes.backArrow}
            />
          </NavLink>
          <div className={classes.programMainInfo}>
            <h3 className={classes.location}>
              <LocationOnIcon
                style={{ color: "#f8f8f8", fontSize: 25, marginRight: 10 }}
              />
              {program?.location}
            </h3>
            <h1 className={classes.name}>
              {program?.name.replaceAll(".", " ")}
            </h1>
            {!program?.isRecurrent && (
              <div className={classes.dateDuration}>
                <p>{parseDate(program?.startDate)}</p>
                <p>{parseDate(program?.endDate)}</p>
              </div>
            )}
          </div>
          <div className={classes.main}>
            <section className={classes.intro}>
              <div className={classes.summary}>
                <SectionTitle title="Program Summary" />
                <p>{program?.summary}</p>
              </div>
              <div className={classes.introCard}>
                <div className={classes.spotsAvailable}>
                  <h2>{program?.spotsAvailable}</h2>
                  <h3>Available Spots</h3>
                </div>
                <div className={classes.startPrice}>
                  <p>Starting From</p>
                  <h2>{currency + program?.price}</h2>
                  {!program?.isRecurrent && (
                    <h3>
                      Application deadline:
                      <span>{parseDate(program?.applicationDeadline)}</span>
                    </h3>
                  )}
                </div>
                <section className={classes.book}>
                  <button
                    className={
                      program.isAvailable ? classes.btn : classes.btnDisabled
                    }
                    onClick={openGoogleForm}
                  >
                    {" "}
                    Book Now <h6>→</h6>
                  </button>
                </section>
              </div>
            </section>
            <section>
              <SectionTitle title="Highlights" />
              <Highlights highlights={program?.highlights} />
            </section>
            {program?.activities?.length > 0 ? (
              <section>
                <SectionTitle title="Activities" />
                <Activities activities={program?.activities} />
              </section>
            ) : null}
            {program?.itinerary?.length > 0 ? (
              <section>
                <SectionTitle title="Itinerary" />
                <Itinerary itinerary={program?.itinerary} />
              </section>
            ) : null}
            <section>
              <SectionTitle title="Price & Duration" />
              <div className={classes.mainPrice}>
                <div className={classes.priceCards}>
                  {program?.slots?.map((slot) => {
                    return (
                      <PriceCard
                        key={slot?.duration}
                        title={slot?.duration + " Days"}
                        duration={slot?.dates}
                        price={slot?.price}
                        currency={currency}
                      />
                    );
                  })}
                </div>
                <div className={classes.details}>
                  <PriceDetails
                    includes={true}
                    items={program?.priceIncludes}
                  />
                  <PriceDetails
                    includes={false}
                    items={program?.priceExcludes}
                  />
                </div>
              </div>
            </section>
            {program?.details?.length > 0 ? (
              <section className={classes.detailsSection}>
                <SectionTitle title="Program Details" />
                {program?.details?.map((p) => (
                  <ExpandableSection key={p.title} {...p} />
                ))}
              </section>
            ) : (
              ""
            )}
          </div>

          {openBooking ? (
            <BookingModal
              setOpenBooking={setOpenBooking}
              slots={program?.slots}
            />
          ) : null}
        </div>
      )}
      <Anchor top={200} topOffset={300} />
    </>
  );
}

export default ProgramDetails;

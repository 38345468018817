import React, { useState, useEffect } from 'react';
import classes from './anchor.module.css';

import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

export default function Anchor(props) {
  const [show, setShow] = useState(false);
  const [nearBottom, setNearBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const topOffset = props?.topOffset || 100;
      const bottomOffset = props?.bottomOffset || 50;

      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const totalHeight = document.documentElement.scrollHeight;

      // Show button after scrolling past topOffset
      setShow(scrollTop > topOffset);

      // Detect if the user is near the bottom of the page
      setNearBottom(scrollTop + windowHeight >= totalHeight - bottomOffset);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [props]);

  const handleClick = () => {
    const top = props?.top || 0;
    window.scrollTo({ top, behavior: 'smooth' });
  };

  return (
    <div
      className={`${classes.container} ${show ? classes.show : ''} ${
        nearBottom ? classes.nearBottom : ''
      }`}
    >
      <button className={classes.button} onClick={handleClick}>
        <KeyboardArrowUpRoundedIcon className={classes.icon} />
      </button>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import classes from './whatsappIcon.module.css';
import { WhatsApp } from '@mui/icons-material';

export default function WhatsappIcon() {
  const [hideAtBottom, setHideAtBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const bottomOffset = 50; // Adjust this value based on how close to the bottom you want the icon to hide
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const totalHeight = document.documentElement.scrollHeight;

      // Hide the icon if the user is near the bottom of the page
      setHideAtBottom(scrollTop + windowHeight >= totalHeight - bottomOffset);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={`${classes.container} ${hideAtBottom ? classes.hide : ''}`}
    >
      <a
        target="_blank"
        rel="noreferrer noopener"
        href="https://wa.me/201094414168"
      >
        <WhatsApp className={classes.icon} />
      </a>
    </div>
  );
}
